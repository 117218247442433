export var UserEventType;
(function (UserEventType) {
    UserEventType["UserCreated"] = "user.UserCreated";
})(UserEventType || (UserEventType = {}));
export var SubscriberEventType;
(function (SubscriberEventType) {
    SubscriberEventType["SubscriberCreated"] = "subscriber.SubscriberCreated";
})(SubscriberEventType || (SubscriberEventType = {}));
export var ProductEventType;
(function (ProductEventType) {
    ProductEventType["ProductPurchased"] = "product.ProductPurchased";
    ProductEventType["ProductCreated"] = "product.ProductCreated";
    ProductEventType["ProductUpdated"] = "product.ProductUpdated";
    ProductEventType["ProductArchived"] = "product.ProductArchived";
    ProductEventType["ProductRemoved"] = "product.ProductRemoved";
    ProductEventType["ProductSubscriptionRenewed"] = "product.ProductSubscriptionRenewed";
    ProductEventType["ProductSubscriptionPlanUpdated"] = "product.ProductSubscriptionPlanUpdated";
    ProductEventType["ProductAccessRenewed"] = "product.ProductAccessRenewed";
    ProductEventType["ProductSubscriptionEnd"] = "product.ProductSubscriptionEnd";
    ProductEventType["ProductAccessPeriodEnd"] = "product.ProductAccessPeriodEnd";
    ProductEventType["ProductClientImported"] = "product.ClientImported";
    ProductEventType["ProductClientsImportFinished"] = "product.ClientsImportFinished";
    ProductEventType["ProductSubscriptionCancelAtPeriodEnd"] = "product.ProductSubscriptionCancelAtPeriodEnd";
    ProductEventType["ProductSubscriptionResumeAtPeriodEnd"] = "product.ProductSubscriptionResumeAtPeriodEnd";
    ProductEventType["ProductAccessPeriodEndingIn14Days"] = "product.ProductAccessPeriodEndingIn14Days";
    ProductEventType["ProductAccessPeriodEndingIn7Days"] = "product.ProductAccessPeriodEndingIn7Days";
    ProductEventType["ProductAccessPeriodEndingIn3Days"] = "product.ProductAccessPeriodEndingIn3Days";
    ProductEventType["ProductAccessPeriodEndingIn1Day"] = "product.ProductAccessPeriodEndingIn1Day";
    ProductEventType["ProductClientArchived"] = "product.ProductClientArchived";
    ProductEventType["ProductClientRemoved"] = "product.ProductClientRemoved";
    ProductEventType["ProductClientEmailChanged"] = "product.ProductClientEmailChanged";
    ProductEventType["ProductSlugChanged"] = "product.ProductSlugChanged";
    ProductEventType["ProductPaymentFailed"] = "product.ProductPaymentFailed";
})(ProductEventType || (ProductEventType = {}));
export var EmailClientEventType;
(function (EmailClientEventType) {
    EmailClientEventType["ClientAddedToList"] = "emailClient.ClientAddedToList";
    EmailClientEventType["ClientRemovedFromList"] = "emailClient.ClientRemovedFromList";
    EmailClientEventType["ListBindWithProduct"] = "emailClient.ListBindWithProduct";
    EmailClientEventType["ListUnbindFromProduct"] = "emailClient.ListUnbindFromProduct";
    EmailClientEventType["UpdateTagsForBindList"] = "emailClient.UpdateTagsForBindList";
})(EmailClientEventType || (EmailClientEventType = {}));
export var IntegrationEventType;
(function (IntegrationEventType) {
    IntegrationEventType["IntegratedWithInvoiceClient"] = "integration.IntegratedWithInvoiceClient";
    IntegrationEventType["IntegratedDiscordMailingrBotWithServer"] = "integration.IntegratedDiscordMailingrBotWithServer";
    IntegrationEventType["IntegratedDiscordAccountWithMailingr"] = "integration.IntegratedDiscordAccountWithMailingr";
    IntegrationEventType["IntegratedStripeAccount"] = "integration.IntegratedStripeAccount";
})(IntegrationEventType || (IntegrationEventType = {}));
export var ProductReferralsEventType;
(function (ProductReferralsEventType) {
    ProductReferralsEventType["ValidSubscriberFromReferral"] = "productReferrals.ValidSubscriberFromReferral";
    ProductReferralsEventType["SubscriberGetReward"] = "productReferrals.SubscriberGetReward";
    ProductReferralsEventType["SubscriberHasRewardToConfirm"] = "productReferrals.SubscriberHasRewardToConfirm";
    ProductReferralsEventType["SyncedEmailListsWithApp"] = "productReferrals.SyncedEmailListsWithApp";
})(ProductReferralsEventType || (ProductReferralsEventType = {}));
export var SubscriptionEventType;
(function (SubscriptionEventType) {
    SubscriptionEventType["ActivatedNewTrialSubscription"] = "subscription.ActivatedNewTrialSubscription";
    SubscriptionEventType["ChangedCurrentPlan"] = "subscription.ChangeCurrentPlan";
    SubscriptionEventType["InvoicePaid"] = "subscription.InvoicePaid";
    SubscriptionEventType["InvoicePaymentFailed"] = "subscription.InvoicePaymentFailed";
    SubscriptionEventType["ReachTierLimit"] = "subscription.ReachTierLimit";
    SubscriptionEventType["EndOfSubscription"] = "subscription.EndOfSubscription";
    SubscriptionEventType["CancelSubscription"] = "subscription.CancelSubscription";
    SubscriptionEventType["TierLimitsSatisfied"] = "subscription.TierLimitsSatisfied";
})(SubscriptionEventType || (SubscriptionEventType = {}));
export var CommunityEventType;
(function (CommunityEventType) {
    CommunityEventType["Update"] = "community.update";
})(CommunityEventType || (CommunityEventType = {}));
